<template>
  <div>
    <div class="row">
      <div class="col">
        <CWidgetIcon
            :header="String(total)"
            text="СКУ"
            color="gradient-info"
            :icon-padding="false"
        >
          <CIcon name="cil-group" class="mx-5 " width="24"/>
        </CWidgetIcon>
      </div>
      <div class="col">
        <CWidgetIcon
            :header="stats.products"
            text="Товары"
            color="gradient-success"
            :icon-padding="false"
        >
          <CIcon name="cil-gift" class="mx-5 " width="24"/>
        </CWidgetIcon>
      </div>
      <div class="col">
        <CWidgetIcon
            :header="stats.orders"
            text="Заказы"
            color="gradient-danger"
            :icon-padding="false"
        >
          <CIcon name="cil-cart" class="mx-5 " width="24"/>
        </CWidgetIcon>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <CWidgetIcon
            :header="stats.customers"
            text="Покупатели"
            color="gradient-warning"
            :icon-padding="false"
        >
          <CIcon name="cil-group" class="mx-5 " width="24"/>
        </CWidgetIcon>
      </div>
      <div class="col">
        <CWidgetIcon
            :header="stats.avgSkuCount"
            text="Товаров в чеке"
            color="gradient-dark"
            :icon-padding="false"
        >
          <CIcon name="cil-graph" class="mx-5 " width="24"/>
        </CWidgetIcon>
      </div>
    </div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <slot name="header">
              <CRow>
                <CCol class="col-12">
                  <CIcon name="cil-gift"/> {{caption}} <span class="text-muted">({{ total || '0' }})</span>
                </CCol>
                <CCol class="col-12">
                  <search-filter ref="searchFilter" :search="search" :perform-search="performSearch"></search-filter>
                </CCol>
                <CCol class="col-12">
                  <CRow>
                    <CCol class="col-4">
                      <CButton @click="performSearch" :disabled="loading" color="success">
                        <span v-show="loading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                        Искать
                      </CButton>
                      <CButton @click="clearSearch" :disabled="loading" class="mx-2" color="danger">Очистить</CButton>

                      <CButton @click="performDownload" class="float-right" color="primary">Скачать</CButton>
                    </CCol>
                  </CRow>
                </CCol>
              </CRow>
            </slot>
          </CCardHeader>
          <CCardBody>
            <CDropdown
                color="primary"
                toggler-text="Колонки"
                class="my-2 float-right"
            >
              <div class="dropdown-item" :key="field.key" v-for="field in fields">
                <input :id="field.key" :value="field.key" name="checkedFields" type="checkbox" v-model="checkedFields" />
                <label :for="field.key"><span>{{field.label}}</span></label>
              </div>
            </CDropdown>
            <CDataTable
                :hover=true
                :striped=true
                :border=true
                :items="data"
                :fields="allowedFields()"
                :pagination=false
            >
              <template #sales-header>
                <div style="cursor: pointer" @click="toggleSort('sales')">Продажи <CIcon
                    class="icon-transition float-right"
                    :name="sortIcon('sales')"></CIcon>
                </div>
              </template>
              <template #image="{item}">
                <td>
                  <CImg
                      :src="item.image"
                      block
                      width="50px"
                      shape="rounded"
                      :thumbnail=true
                  />
                </td>
              </template>
            </CDataTable>
          </CCardBody>
          <CCardFooter>
            <CPagination
                v-if="total !== null"
                class="float-right"
                :activePage.sync="page"
                :pages="pages"
                align="center"
                v-on:update:activePage="loadData"
            />
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import product from '../../api/product'
import SearchFilter from '@/views/products/search/SearchFilter'
import ProductsSearch from '@/views/products/search/ProductsSearch'
import exportApi from '@/api/export'

export default {
  name: 'List',
  components: { SearchFilter },
  data() {
    return {
      checkedFields: ['id', 'image', 'title', 'sku', 'brand', 'categories', 'sizes', 'colors', 'sales'],
      fields:  [
        {
          key: 'id',
          label: 'ID'
        },
        {
          key: 'image',
          label: 'Картинка'
        },
        {
          key: 'title',
          label: 'Название'
        },
        {
          key: 'sku',
          label: 'СКУ'
        },
        {
          key: 'brand',
          label: 'Бренд'
        },
        {
          key: 'categories',
          label: 'Категории'
        },
        {
          key: 'sizes',
          label: 'Размеры'
        },
        {
          key: 'colors',
          label: 'Цвета'
        },
        {
          key: 'sales',
          label: 'Продажи'
        }
      ],
      loading: false,
      search: new ProductsSearch(),
      caption: 'Товары',
      page: 1,
      total: null,
      stats: {
        products: '--',
        orders: '--',
        customers: '--',
        avgSkuCount: '--'
      },
      data: [],
      error: null,
      collapseDuration: 0
    }
  },
  methods: {
    allowedFields() {
      return this.fields.filter(field => this.checkedFields.includes(field.key))
    },
    sortIcon (name) {
      if (this.search.sort.name === name) {
        if (this.search.sort.direction === 'asc') {
          return 'cil-arrow-bottom'
        } else {
          return 'cil-arrow-top'
        }
      } else {
        return 'cil-swap-vertical'
      }
    },
    toggleSort (name) {
      if (this.search.sort.name === name) {
        if (this.search.sort.direction === 'asc') {
          this.search.sort.direction = 'desc'
        } else {
          this.search.sort.name = null
          this.search.sort.direction = null
        }
      } else {
        this.search.sort.name = name
        this.search.sort.direction = 'asc'
      }

      this.performSearch()
    },
    performDownload() {
      exportApi.perform('catalog', {
        query: this.search.getQuery(),
        description: this.search.getDescription()
      })
    },
    clearSearch () {
      this.search.clear()
      this.performSearch()
    },
    performSearch () {
      product.searchTotal(this.search.getQuery()).then(r => this.total = r.data.total)

      this.page = 1
      this.loadData().then(() => {
        this.$refs.searchFilter.loadFilters()
      })
      this.loadStats()
    },
    toggleDetails (item, index) {
      this.$set(this.data[index], '_toggled', !item._toggled)
      this.collapseDuration = 300
      this.$nextTick(() => { this.collapseDuration = 0})
    },
    async loadData () {
      const onSuccess = r => this.data = r.data
      const onError = e => this.error = e

      this.loading = true

      try {
        let resp = await product.search(this.search.getQuery(this.page))
        onSuccess(resp)
        this.loading = false

        return resp
      } catch (e) {
        this.loading = false

        onError(e)
      }
    },
    loadTotal () {
      product.searchTotal(this.search.getQuery()).then(r => this.total = r.data.total)
    },
    loadStats () {
      product.searchStats(this.search.getQuery()).then(r => {
        this.stats.orders = String(r.data.orders)
        this.stats.products = String(r.data.products)
        this.stats.customers = String(r.data.customers)
        this.stats.avgSkuCount = String(Math.round(r.data.total / r.data.orders * 10) / 10)
      })
    },
    products (data) {
      return data.map(p => {
        return {
          id: p.foreignId,
          title: p.title,
          brand: p.brand.title,
          categories: p.categories.map(c => c.title).join(', '),
          image: p.image || 'https://extremstyle.ua/images/image-not-found.png',
          variants: p.variants
        }
      })
    },
    variants (items) {
      return items.map(v => {
        return {
          sku: v.sku,
          sizes: v.sizes.map(size => size.titleWithChart).join(', '),
          colors: v.colors.map(color => color.title).join(', '),
        }
      })
    }
  },
  computed: {
    pages () {
      return Math.floor(this.total/30)
    }
  },
  created() {
    this.$store.commit('common/set', ['breadcrumbs', [
      {
        text: 'Главная',
        to: '/dashboard'
      },
      {
        text: 'Товары'
      }
    ]])
    this.loadTotal()
    this.loadData()
    this.loadStats()
  }
}
</script>

<style>
.dropdown-item label {
  margin-left: 0.5rem;
  margin-bottom: 0;
}
</style>