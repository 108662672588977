import Search from '@/components/search/Search'
import TermSearch from '@/components/search/terms/TermSearch'
import LikeSearch from '@/components/search/terms/LikeSearch'
import DateSearch from '@/components/search/terms/DateSearch'
import ArraySearch from '@/components/search/terms/ArraySearch'
import StringSearch from '@/components/search/terms/StringSearch'

class ProductsSearch extends Search {
    sorts = {
        'sales': 'count'
    }

    filters = {
        orderDate : new DateSearch('orderDate', 'Период покупок'),
        categories : new ArraySearch('categories', 'Категории',  'id'),
        brands : new ArraySearch('brands', 'Бренды', 'id'),
        shops : new ArraySearch('shops', 'Магазины'),
        sku : new LikeSearch('sku', 'СКУ'),
        title : new LikeSearch('title', 'Артикул'),
        commonOrderSku: new StringSearch('commonOrderSku', 'С этим СКУ покупают'),
        commonOrderTitle: new StringSearch('commonOrderTitle', 'С этим артикулом покупают'),
        purchasesSum : new TermSearch('purchasesSum', 'Сумма покупок'),
        purchasesCount : new TermSearch('purchasesCount', 'Кол-во покупок'),
        singleItemSum : new TermSearch('singleItemSum', 'Сумма одной позиции в чеке'),
        totalItemsCount : new TermSearch('totalItemsCount', 'Кол-во покупок товара'),
        oneOrderSum : new TermSearch('oneOrderSum', 'Сумма одного чека'),
        catalogReturns : new TermSearch('catalogReturns', 'Кол-во возвратов'),
        genders : new ArraySearch('genders', 'Пол', 'id'),
        years : new ArraySearch('years', 'Год', 'id'),
        sizes : new ArraySearch('sizes', 'Размеры', 'id'),
        types : new ArraySearch('types', 'Тип/Наименование/Назначение', 'id'),
        birthDay : new DateSearch('birthDay', 'День рождения', /(\d{2}-\d{2}) - (\d{2}-\d{2})/),
        catalogDiscounts : new TermSearch('catalogDiscounts', 'Кол-во скидочных позиций'),
        cardNumber : new LikeSearch('cardNumber', 'Номер карты'),
        email : new StringSearch('email', 'E-Mail'),
        phone : new StringSearch('phone', 'Телефон'),
        fullName : new StringSearch('fullName', 'Ф.И.О.')
    }
}

export default ProductsSearch
